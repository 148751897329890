.new-home {
  width: 100%;
  margin: 0;
  display: block;
}
.mobile-home {
  width: 100%;
  margin: 0;
  display: none;
}
@media only screen and (max-width: 500px) {
  .mobile-home {
    display: block;
  }
  .new-home {
    display: none;
  }
}

@media only screen and (max-width: 580px) {
  .new-home {
    display: none;
  }
}
.new-home-top {
  height: 100vh;
  width: 100%;
}

.new-home-top-image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.new-home-images-gif {
  position: absolute;
  right: 80px;
  top: calc(100vh - 100px);
  z-index: 2;
  width: 18px;
  cursor: pointer;
}
.new-home-about {
  width: 100%;
  background-image: url("/images/texture.png");
  background-repeat: repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 32px;
  box-sizing: border-box;
}

.new-home-about-image {
  height: 500px;
  width: auto;
  margin-right: 24px;
  background-size: 264px 238px;
}

.new-home-about-title {
  font: normal normal 600 32px/37px ofelia-text;
  color: #545454;
  margin-bottom: 8px;
}

.new-home-about-desc {
  font: normal normal 500 25px/37px ofelia-text;
  color: #545454;
  max-width: 963px;
}

.new-home-about-read-more {
  font: normal normal 600 24px/37px ofelia-text;
  color: #767676;
  text-decoration: none;
  cursor: pointer;
  margin-top: 8px;
}

.new-home-about-read-more:visited {
  color: #545454;
  text-decoration: none;
}

.new-home-about-read-more:hover {
  color: #54545499;
  text-decoration: none;
}

@media only screen and (max-width: 1100px) {
  .new-home-about-desc {
    font: normal normal 300 22px/37px ofelia-text;

  }
}
@media only screen and (max-width: 920px) {
  .new-home-about-image {
    margin-right: -50px;
  }
  .new-home-about-desc {
    font: normal normal 300 20px/33px ofelia-text;
  }
}
@media only screen and (max-width: 850px) {
  .new-home-about-image {
    margin-right: -100px;
  }
  .new-home-about-desc {
    font: normal normal 300 21px/27px ofelia-text;
  }
  .new-home-about-title {
    font: normal normal 600 30px/40px ofelia-text;
  }
}
@media only screen and (max-width: 770px) {
  .new-home-about-image {
    margin-right: -200px;
  }
}
@media only screen and (max-width: 660px) {
  .new-home-about-image {
    margin-right: -50%;
  }
}
.new-home-recent {
  margin: 60px auto;
  width: 100%;
  max-width: 1512px;
  padding: 0 12px;
  box-sizing: border-box;
}

.new-home-recent-title {
  margin-left: 20px;
  font: normal normal 600 36px/44px ofelia-text;
  letter-spacing: -3.25px;
  color: #686868;
  margin-bottom: 30px;
}

.new-home-recent-items {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.new-home-recent-item {
  display: block;
  margin-bottom: 16px;
}
.new-home-recent-img-container {
  width: 496px;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-home-recent-img {
  width: 450px;
  height: 450px;
  transition: all 0.3s linear;
  cursor: pointer;
}

.new-home-recent-img:hover {
  transform: scale(1.02);
}

.new-home-recent-img-title {
  margin-top: 8px;
  cursor: pointer;
  text-decoration: none;
  font: normal normal 300 27px/35px ofelia-text;
  color: #000000;
  margin-left: 26px;
}

.new-home-recent-img-title:visited {
  color: #000000;
}

@media only screen and (max-width: 1511px) {
  .new-home-recent-img-container {
    width: 456px;
    height: 410px;
  }

  .new-home-recent-img {
    width: 410px;
    height: 410px;
  }
}

@media only screen and (max-width: 1410px) {
  .new-home-recent-img-container {
    width: 400px;
    height: 370px;
  }

  .new-home-recent-img {
    width: 370px;
    height: 370px;
  }
}

@media only screen and (max-width: 1239px) {
  .new-home-recent-items {
    justify-content: center;
  }
}

.new-home-category {
  width: 100%;
  position: relative;
  height: 360px;
  overflow: hidden;
}

.new-home-category-container {
  margin-top: 304px;
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  width: 100%;
  /*max-width: 1712px;*/
  padding: 5px 12px;
  box-sizing: border-box;
  background-color: rgba(230, 230, 230, 0.7)
}

.new-home-category-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.new-home-category-background-image {
  height: 100%;
  object-fit: contain;
}

.new-home-category-background-gray {
  opacity: 1;
  transition: opacity 0.3s linear;
}

.new-home-category:hover .new-home-category-background-gray {
  opacity: 0;
}

.new-home-category-title {
  text-decoration: underline;
  cursor: pointer;
  font: normal normal 500 41px/47px ofelia-text;
  color: #FFFFFF;
  text-shadow: 0px 4px 8px #000000DB;
}

.new-home-category-title:visited {
  text-decoration: underline;
  color: #FFFFFF;
}

.new-home-category-title:hover {
  text-decoration: underline;
  color: #EEEEEE;
}

.new-home-footer-support {
  height: 48px;
  width: 100%;
  background-color: #E1E1E1;
}

.new-home-footer {
  width: 100%;
  background-image: url("/images/footer_bg.png");
  background-repeat: no-repeat;
  background-size: 1920px 700px;
}

.new-home-footer-container {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1512px;
  padding: 60px 12px;
  box-sizing: border-box;
}

.new-home-footer-title {
  font: normal normal 600 40px/50px ofelia-text;
  margin-bottom: 32px;
  color: #FFFFFF;
}
.new-home-footer-logos {
  /*margin-top: -10%;*/
  width: 100%;
}

.new-home-header {
  background-color: rgba(246, 246, 246, 0.15);
  transition: all 0.3s linear;
  box-shadow: none;
}

.new-home-header-active {
  background-color: rgba(246, 246, 246, 1);
  box-shadow: 0px 5px 8px rgba(0, 0, 0, 0.25);
}

.new-home-footer-logos-container-1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
}

.new-home-footer-logos-container-2 {
  width: 100%;
  max-width: 900px;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}
.new-home-footer-logo-1 {
  height: 100px;
  margin: 12px 12px 32px;
}

.new-home-footer-logo {
  height: 70px;
  margin: 12px 12px 32px;
}

.new-home-footer-logos-v2 {
  display: flex;
  /*width: 100%;*/
  padding: 0 32px;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
}

.new-home-footer-logo-4 {
  height: 50px;
  margin: 12px 0 32px 12px;
}

.new-home-footer-logo-5 {
  height: 120px;
  margin: 12px 0 32px 12px;
}
